import React, { useState, useEffect, useRef } from "react";
import fireDb from "../firebase";
/* global google */

const initialState = {
  studentname: "",
  cohort: "",
  locationname: "",
  latitude: "",
  longitude: "",
  status: "In Review",
  formShow: true,
  isError: false, // Used for firebase issues or address issues
  errorMessage: "",
  cohortOptions: [{label: "Please Select", value: ""}]
};

const MapForm = () => {

  const [state, setState] = useState(initialState);
  const count = useRef(null);

  const { studentname, cohort, locationname, latitude, longitude, status, errorMessage, formShow, isError, cohortOptions } = state;

  useEffect(() => {
    if (count.current == null) {
      fireDb.child("settings").on("value", (snapshot) => {
        if (snapshot.val() !== null) {
          let cohortKeys = Object.values(snapshot.val().main);

          for (let i = 0; i < cohortKeys.length; i++) {
            cohortOptions.push({label: cohortKeys[i], value: cohortKeys[i]})
              setState({ ...state, cohortOptions: cohortOptions });
          } 
        }
      });
    }
    return () => { count.current = 1; }
  }, [state, cohortOptions]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "locationname") {
        const autocomplete = new google.maps.places.Autocomplete(document.getElementById('locationname'));
        google.maps.event.addListener(autocomplete, 'place_changed', function () {
            const places = autocomplete.getPlace();
            if (!places.geometry) { return; }
            const fullAddress = places.formatted_address;
            const latVal = places.geometry.location.lat();
            const lngVal = places.geometry.location.lng();
            setState({ ...state, locationname: fullAddress, latitude: latVal.toString(), longitude: lngVal.toString() });
        });
  
    } else {
      setState({ ...state, [name]: value });
    }

  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // This is really to catch any errors from the Google Maps location selection
    if (locationname === "" || latitude === "" || longitude === "") {
      setState({ ...state, isError: true, errorMessage: "Please select a location from the input box." });
      return;
    }

    try {
      fireDb.child("messages").push().set({
        studentname: studentname,
        cohort: cohort,
        locationname: locationname,
        latitude: latitude,
        longitude: longitude,
        status: status
      });
    } catch (err) {
      setState({ ...state, isError: true, errorMessage: "There was an issue trying to submit your form. Please try later." });
      return;
    }
    
    setState({ ...state, formShow: false });
  };

  return (
    <div style={{ marginTop: "50px" }}>
      <h4>Map Submission Form</h4>
      {isError &&
      <div className="alert-section">
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      </div>
      }
      {formShow ?
        <form
          onSubmit={handleSubmit}
        > 
          <div className = "row">
              <div className = "col-md-12">
                  <div className = "form-area">
                    <label htmlFor="studentname">Name</label>
                    <input
                        type="text"
                        id="studentname"
                        name="studentname"
                        placeholder="E.g John Smith"
                        required={true}
                        value={studentname}
                        onChange={handleInputChange}
                    />
                  </div>
                  <div className = "form-area">
                    <label htmlFor="cohort">Cohort</label>
                    <select
                        type="text"
                        id="cohort"
                        name="cohort"
                        required={true}
                        value={cohort}
                        onChange={handleInputChange}
                    >
                      {cohortOptions.map((option, index) => <option key={index} value={option.value}>{option.label}</option>)}
                    </select>
                  </div>
                  <div className = "form-area">
                    <label htmlFor="locationname">Location</label>
                    <input
                        type="text"
                        id="locationname"
                        name="locationname"
                        placeholder="Start typing a location..."
                        required={true}
                        onChange={handleInputChange}
                    />
                    {locationname.length > 0 ?
                        
                        <div className = "selection">
                          <p>
                            <strong>You selected:</strong> <em>{locationname}</em>
                          </p>
                        </div>
                    :
                        <p>Type and select a city or location from the dropdown.</p>
                    }
                  </div>
                  <input type="submit" value={"Submit"}/>
              </div>
          </div>
        </form>
      :
      <div className="alert-section">
        <div className="alert alert-success" role="alert">
          Thank you for your submission. This will be reviewed shortly.
        </div>
      </div>
      }
    </div>
  );
};

export default MapForm;
